import * as React from 'react'
import styled, { css } from 'styled-components'
import ReactMarkdown from 'react-markdown'

import { COLORS, glyphs } from '@ulule/owl-kit-components/dist/next.esm'
import { Metadata, Prop } from './types'

const TableWrapper = styled.div`
  margin: 32px 0;
  overflow: auto;
`

const Table = styled.table`
  border: 0;
  text-align: left;
  border-collapse: collapse;
  max-width: 100%;
  width: 100%;
`

const Tr = styled.tr`
  border-bottom: 1px solid ${COLORS.GREY_SHADE_4};
`

const Th = styled.th<{ width?: number }>`
  color: ${COLORS.GREY_SHADE_3};
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 8px 0;

  ${({ width }) => {
    if (width) {
      return css`
        width: ${width};
      `
    }
  }}
`

const Td = styled.td`
  color: ${COLORS.PRIMARY_BLACK};
  font-size: 16px;
  font-weight: 300;
  vertical-align: top;
  padding: 16px 8px;
  white-space: pre-wrap;

  /* markdown */
  p {
    color: ${COLORS.GREY_SHADE_3};
    margin: 0;
  }
`

const PropName = styled.span`
  font-weight: 700;
`
const From = styled.p`
  font-weight: 300;
  color: ${COLORS.GREY_SHADE_3};
`

const DefaultTd = styled(Td)`
  text-align: left;
`

const Verbatim = styled.span`
  font-family: 'Source Code Pro', monospace;
  white-space: pre-wrap;
  font-weight: 500;
`

const Required = styled(glyphs.fill.CheckCircle).attrs({
  title: 'Required',
})`
  display: inline-block;
  color: ${COLORS.PRIMARY_BLUE};
  width: 13px;
  height: 13px;
`

type PropListProps = {
  metadata: Metadata
}

export function PropsTable({ metadata }: PropListProps): React.ReactElement<PropListProps> {
  if (metadata.props.length === 0) {
    return <></>
  }
  return (
    <TableWrapper>
      <Table>
        <thead>
          <Tr>
            <Th width={300}>Property</Th>
            <Th>Description</Th>
            <Th width={300}>Default value</Th>
          </Tr>
        </thead>
        <tbody>
          {metadata.props.map(prop => {
            return (
              <Tr key={prop.name}>
                <Td>
                  <PropName>{prop.name}</PropName> {prop.required && <Required />}{' '}
                  {prop.from && <From>from {prop.from}</From>}
                </Td>
                <Td>
                  <Verbatim>{getProp(prop)}</Verbatim>
                  {prop.docblock && <ReactMarkdown source={prop.docblock} />}
                </Td>
                {!prop.defaultValue ? (
                  <DefaultTd>
                    <p>-</p>
                  </DefaultTd>
                ) : (
                  <DefaultTd>{prop.defaultValue && <Verbatim>{prop.defaultValue.value}</Verbatim>}</DefaultTd>
                )}
              </Tr>
            )
          })}
          {metadata.extends?.map(name => {
            return (
              <Tr key={name}>
                <Td>
                  <PropName>...rest</PropName>
                </Td>
                <Td>
                  <Verbatim>{name}</Verbatim>
                </Td>
              </Tr>
            )
          })}
        </tbody>
      </Table>
    </TableWrapper>
  )
}

function getProp(prop: Prop): string {
  if (prop.tsType) {
    return prop.tsType.raw || prop.tsType.name
  }

  if (prop.type) {
    return legacyPropType(prop.type)
  }

  return '[Type Not Found]'
}

const RE_OBJECTOF = /(?:React\.)?(?:PropTypes\.)?objectOf\((?:React\.)?(?:PropTypes\.)?(\w+)\)/

type Type = Prop['type']

function legacyPropType(type: Type): string {
  switch (type.name.toLowerCase()) {
    case 'instanceof':
      return `Class(${type.value})`
    case 'enum':
      return type.value ? ((type.value as unknown) as Type[]).map(v => `${v.value}`).join(' │ ') : type.raw || ''
    case 'union':
      return type.value
        ? ((type.value as unknown) as Type[]).map(t => `${legacyPropType(t)}`).join(' │ ')
        : type.raw || ''
    case 'array':
      return type.raw || ''
    case 'arrayof':
      return `Array<${legacyPropType((type.value as unknown) as Type)}>`
    case 'custom':
      if (type.raw && (type.raw.indexOf('function') !== -1 || type.raw.indexOf('=>') !== -1)) {
        return 'Custom(Function)'
      } else if (type.raw && type.raw.toLowerCase().indexOf('objectof') !== -1) {
        const m = type.raw.match(RE_OBJECTOF)
        if (m && m[1]) return `ObjectOf(${m[1]})`
        return 'ObjectOf'
      }
      return 'Custom'
    case 'bool':
      return 'Boolean'
    case 'func':
      return 'Function'
    case 'shape': {
      const shape = type.value as any
      const rst = {} as any

      Object.keys(shape).forEach(key => {
        rst[key] = legacyPropType(shape[key])
      })

      return JSON.stringify(rst, null, 2)
    }
    default:
      return type.name
  }
}
