import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'

import { PropsTable } from '../components/PropsTable'
import { ComponentInfo } from '../components/ComponentInfo'
import { Playground } from '../components/Playground'
import { CodeBlock } from '../components/CodeBlock'
import { SEO } from '../components/SEO'
import { Layout } from './Layout'
import { Metadata } from '../components/types'
import { Documentation } from '../documenter/document/types'

type DocLayoutProps = {
  data: Data
}

export default function DocLayout({ data }: DocLayoutProps): React.ReactElement<DocLayoutProps> {
  const metadata = getMetadata(data)
  return (
    <Layout>
      <SEO title={`${data.mdx.frontmatter.displayName}`} />
      <MDXProvider
        components={{
          PropsTable: () => PropsTable({ metadata }),
          ComponentInfo: (props: { path: string; importCode: string }) =>
            ComponentInfo({
              metadata,
              frontmatter: data.mdx.frontmatter,
              ...props,
            }),
          Playground: Playground,
          CodeBlock: CodeBlock,
        }}
      >
        <MDXRenderer>{data.mdx.body}</MDXRenderer>
      </MDXProvider>
    </Layout>
  )
}

function getMetadata(data: Data): Metadata {
  const doc: Documentation = JSON.parse(data.documentation.documentation)
  return {
    displayName: data.documentation.displayName,
    docblock: doc.docblock.base.join('\n'),
    props: Object.entries(doc.lib).map(([_, prop]) => ({
      tsType: { raw: prop.type, name: prop.name },
      type: { name: prop.name },
      name: prop.name,
      required: !prop.optional,
      docblock: prop.docblock,
      defaultValue: prop.defaultValue ? { value: prop.defaultValue } : undefined,
      from: prop.from,
    })),
  }
}

type Data = {
  mdx: {
    body: string
    frontmatter: {
      displayName: string
      group?: string
      subgroup?: string
      flag?: string
      disabled?: boolean
    }
  }
  documentation: {
    displayName: string
    documentation: string
  }
}

export const query = graphql`
  query($id: String!, $displayName: String!) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        displayName
        group
        subgroup
        flag
        disabled
      }
    }
    documentation(displayName: { eq: $displayName }) {
      displayName
      documentation
    }
  }
`
