import * as React from 'react'
import styled from 'styled-components'

import { GithubLogo } from './Icons'
import { githubPath } from '../utils/githubPath'

const Button = styled.a`
  display: inline-block;
  border: 1px solid #bdbdbd;
  background-color: #ffffff;
  color: #bdbdbd;
  border-radius: 3px;
  text-decoration: none;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 300;
  height: 32px;
  line-height: 32px;

  &:hover {
    transition: all 150ms ease-in-out;
    background-color: #bdbdbd;
    color: #ffffff;
  }

  svg {
    color: currentColor;
  }
`

type GithubButtonProps = {
  path: string
}

export function GithubButton({ path }: GithubButtonProps): React.ReactElement<GithubButtonProps> {
  return (
    <Button href={githubPath(`src/${path}`)} target="_blank" rel="noopener">
      <span>View on GitHub</span>
      &nbsp;
      <GithubLogo />
    </Button>
  )
}
