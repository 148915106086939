import * as React from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

import { CodeExample } from './CodeExample'
import { GithubButton } from './GithubButton'
import { Metadata, FrontMatter } from './types'
import { Flag } from './Flag'

const Wrapper = styled.div``

const Import = styled.div``

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 32px 0 16px;
`

const Name = styled.h2`
  display: block;
  font-size: 32px;
  flex: 1;
  margin: 0;
`

const Description = styled.div`
  color: #999999;
  font-size: 18px;
  font-weight: 600;
  margin: 16px 0 0;
`

const ComponentFlag = styled(Flag)`
  margin-right: 10px;
`

type ComponentInfoProps = {
  metadata: Metadata
  frontmatter: FrontMatter
  path: string
  importCode: string
}

export function ComponentInfo({ metadata, path, importCode, frontmatter }: ComponentInfoProps) {
  return (
    <Wrapper>
      <Row>
        {frontmatter.flag && <ComponentFlag>{frontmatter.flag}</ComponentFlag>}
        <Name>{metadata.displayName}</Name>
        {path && <GithubButton path={path} />}
      </Row>
      {metadata.docblock && (
        <Description>
          <ReactMarkdown source={metadata.docblock} />
        </Description>
      )}
      {importCode && (
        <Import>
          <CodeExample code={importCode} enableCopy />
        </Import>
      )}
    </Wrapper>
  )
}
